import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const AdminMenuwrap = styled.div`
  width: 100%;
  height: 120px;
  background-color: pink;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdminNav = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const LinkWrap = styled.div``;

const CustomLink = styled(Link)`
  text-decoration: none;
  // border: 2px solid blue;
  padding: 10px;
`;

const AdminMenu = () => {
  return (
    <AdminMenuwrap>
      <AdminNav>
        <LinkWrap>
          <CustomLink to="/admin/lista-produse">Produse</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-menu-items">Menu items</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-categorii">Categorii</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-subcategorii">Subcategorii</CustomLink>
        </LinkWrap>      
        <LinkWrap>
          <CustomLink to="/admin/lista-ingrediente">Ingrediente</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-teme">Teme</CustomLink>
        </LinkWrap>
        <LinkWrap>
          <CustomLink to="/admin/lista-imagini-teme">Imagini teme</CustomLink>
        </LinkWrap>
      </AdminNav>
    </AdminMenuwrap>
  );
};

export default AdminMenu;
