import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../config";

const useGetProductsConditionally = (menuItemId, categoryId) => {
  // console.log("menuItemId:", menuItemId);
  // console.log("categoryId:", categoryId);
  const fetchProducts = async () => {
    const endpoint = `products/getProductsForProductListPage/${menuItemId}/${
      categoryId === undefined ? "undefined" : categoryId
    }`;
    const { data } = await Axios.get(endpoint);
    return data;
  };

  return useQuery({
    queryKey: ["getProducts", menuItemId, categoryId],
    queryFn: fetchProducts,
    enabled: !!menuItemId, // Interogare activată doar dacă menuItemId există
  });
};

export default useGetProductsConditionally;
