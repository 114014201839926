import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import LoginForm from "../Components/LoginForm";
import AdminMenu from "./AdminMenu";


const LoginPageWrap = styled.div``;
const AdminNavbarLayout = () => {
  const [logged, setLogged] = useState(true);
  return (
    <LoginPageWrap>
      {!logged && <LoginForm />}
      {logged && (
        <>             
          <AdminMenu />          
          <Outlet />
        </>
      )}
    </LoginPageWrap>
  );
};

export default AdminNavbarLayout;
