export const getIngredientsStyles = (currentTheme) => ({
  control: (provided, state) => ({
    ...provided,
    outline: "none",
    width: "100%",
    height: "30px",
    border: `1px solid ${
      currentTheme
        ? currentTheme.productListPageSelectListControlBorderColor
        : "grey"
    }`,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: currentTheme ? currentTheme.productPageTextColor : "grey",
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: "4px",
    width: "100%",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    maxHeight: "200px",
    overflowY: "auto",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 999,
    borderRadius: "0 0 10px 10px",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "inherit",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
});
