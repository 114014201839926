import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Select, { components } from "react-select";

import BackgroundImg from "../Components/ProductPage/BackgroundImg";
import useGetCompleteProductById from "../../myHooks/product/useGetCompleteProductById";
import Header from "../Components/ProductPage/Header";
import SpecificationsDiv from "../Components/ProductPage/SpecificationsDiv";
import RecipeDiv from "../Components/ProductPage/RecipeDiv";
import IngredientsDiv from "../Components/ProductPage/IngredientsDiv";
import LoadingSpinner from "../Components/LoadingSpinner";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;

const FullPageWrap = styled.div`
  margin-top: 90px;
  width: 100%;
  min-height: calc(100vh - 90px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${appear} 2s ease forwards;
`;
const BackgroundMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const ProductWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  border-radius: 25px;
  animation: ${appear} 2.5s ease forwards;

  @media screen and (min-width: 1300px) {
    width: 40%;
    padding: 60px;
    font-size: 17px;
    padding: 60px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    width: 75%;
    padding: 40px;
  }
  @media screen and (min-width: 700px) and (max-width: 1000px) {
    width: 85%;
    padding: 40px;
  }
  @media screen and (min-width: 500px) and (max-width: 700px) {
    width: 95%;
    padding: 30px;
  }
  @media screen and (max-width: 500px) {
    width: 97%;
    padding: 20px;
  }
`;
const ProductWrapContent = styled.div`
  //border: 4px solid blue;
`;
const Body = styled.div`
  // border: 4px solid black;
  width: 100%;
`;

const ProductPage = ({ currentTheme }) => {
  const params = useParams();
  const productId = params.id;
  console.log("productId:", productId);

  //Gasim produsul:
  const {
    data: product,
    isLoading,
    error,
  } = useGetCompleteProductById(productId);
  console.log("product:", product);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error loading products</div>;
  }
  return (
    <FullPageWrap>
      <BackgroundMask
        $maskBackgroundColor={
          currentTheme
            ? currentTheme.productPageBackgroundMaskColor
            : " rgba(0,0,0,0.1)"
        }
      />
      <BackgroundImg currentTheme={currentTheme} />
      <ProductWrap
        style={{
          backgroundColor: currentTheme
            ? `${currentTheme.productContainerBackgroundColor}`
            : "rgba(240, 248, 255,0.5)",
          border: `1px solid ${
            currentTheme
              ? currentTheme.productContainerBorder
              : "rgb(196, 202, 206)"
          }`,
        }}
      >
        {product && (
          <ProductWrapContent>
            <Header product={product} currentTheme={currentTheme} />
            <Body>
              <SpecificationsDiv
                product={product}
                currentTheme={currentTheme}
              />
              <RecipeDiv product={product} currentTheme={currentTheme} />
              <IngredientsDiv product={product} currentTheme={currentTheme} />
            </Body>
          </ProductWrapContent>
        )}
      </ProductWrap>
    </FullPageWrap>
  );
};

export default ProductPage;
