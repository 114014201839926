import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../config";

export const useAddProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (newCompletProduct) => {
      console.log("newCompletProduct:", newCompletProduct);
      const {
        name,
        recipe,
        valability,
        unitOfMeasure,
        visibility,
        image,
        descriptions,
        specifications,
        useInstructions,
        warnings,
        pricesByQnt,
        selectedIngredientsIds,
        selectedMenuItemsCategoriesSubcategories,
      } = newCompletProduct;
      const menuItemsIds = selectedMenuItemsCategoriesSubcategories.map(
        (item) => item.menuItemId
      );

      const categoriesObjArray =
        selectedMenuItemsCategoriesSubcategories.flatMap((menuItem) => {
          return menuItem.categories;
        });
      const categoriesIds = categoriesObjArray.map(
        (category) => category.categoryId
      );
      const subcategoriesIds = categoriesObjArray.flatMap(
        (subcategory) => subcategory.subcategoriesIds
      );

      const formData = new FormData();
      formData.append("name", name);
      formData.append("recipe", recipe);
      formData.append("valability", valability);
      formData.append("unitOfMeasure", unitOfMeasure);
      formData.append("visibility", visibility);
      formData.append("image", image);
      descriptions.forEach((description) => {
        formData.append("descriptions[]", description);
      });
      specifications.forEach((specification) => {
        formData.append("specificationTitles[]", specification.title);
        formData.append("specificationBodies[]", specification.specification);
      });
      useInstructions.forEach((useInstruction) => {
        formData.append("useInstructions[]", useInstruction);
      });

      warnings.forEach((warning) => {
        formData.append("warnings[]", warning);
      });
      pricesByQnt.forEach((priceByQnt) => {
        formData.append(`prices[]`, priceByQnt.price);
        formData.append(`quantities[]`, priceByQnt.quantity);
      });
      selectedIngredientsIds.forEach((id) => {
        formData.append("ingredientsIds[]", id);
      });
      menuItemsIds.forEach((id) => {
        formData.append("menuItemsIds[]", id);
      });
      categoriesIds.forEach((id) => {
        formData.append("categoriesIds[]", id);
      });
      subcategoriesIds.forEach((id) => {
        formData.append("subcategoriesIds[]", id);
      });

      // Iterează prin toate perechile cheie-valoare din FormData
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      return await Axios.post("products/newCompletProduct", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        console.log("Noul produs complet bagat in DB:", res.data);
        return res.data;
      });
    },
    onSuccess: async (newCompletProduct) => {
      console.log("Noul produs complet bagat in DB:", newCompletProduct);
      const newProductId = newCompletProduct.id;
      queryClient.invalidateQueries(["getProductsWithNameImage"]);
      navigate(`/admin/vezi-produs/${newProductId}`);
    },
  });
};
