export const editProductInitialValues = (
  completeProduct,
  categoriesData,
  subcategoriesData
) => {
  const useInstructions = completeProduct?.useInstructions.map(
    (item) => item.instruction
  );

  const specifications = completeProduct?.productSpecifications.map((item) => {
    return { title: item.title, specification: item.specification };
  });

  const warnings = completeProduct?.warnings.map((item) => item.warning);
  const descriptions = completeProduct?.productDescriptions.map(
    (item) => item.description
  );

  const pricesByQnt = completeProduct?.priceByQnts.map((item) => {
    return { price: item.price, quantity: item.quantity };
  });

  const ingredients = completeProduct?.ingredients.map((item) => {
    return { value: item.id, label: item.name };
  });

  const selectedMenuItemsCategoriesSubcategories =
    completeProduct?.menuItems.map((menuItem) => {
      const categoriesForMenuItem = completeProduct?.categories.filter(
        (category) => {
          return category.menuItemId === menuItem.id;
        }
      );
      const selectedCategoriesIds = categoriesForMenuItem.map((category) => {
        return category.id;
      });
      const filteredCategoriesObj = categoriesData
        .filter((cat) => {
          return cat.menuItemId === menuItem.id;
        })
        .map((cat) => {
          return { value: cat.id, label: cat.name };
        });

      const categoriesWithSubcategories = categoriesForMenuItem.map((cat) => {
        const filteredSubcategoriesObj = subcategoriesData
          .filter((subcat) => {
            return subcat.categoryId === cat.id;
          })
          .map((subcat) => {
            return { value: subcat.id, label: subcat.name };
          });
        const subcategoriesForCategory = completeProduct?.subcategories.filter(
          (subcat) => {
            return subcat.categoryId === cat.id;
          }
        );
        const subcategoriesIds = subcategoriesForCategory.map((subcat) => {
          return subcat.id;
        });

        return {
          categoryId: cat.id,
          categoryObj: cat,
          filteredSubcategoriesObj: filteredSubcategoriesObj,
          subcategories: subcategoriesForCategory,
          subcategoriesIds: subcategoriesIds,
        };
      });
      return {
        menuItemId: menuItem.id,
        categories: categoriesWithSubcategories,
        filteredCategoriesObj: filteredCategoriesObj,
        menuItemObj: menuItem,
        selectedCategoriesIds: selectedCategoriesIds,
      };
    });

  const initialValues = {
    selectedMenuItemsCategoriesSubcategories:
      selectedMenuItemsCategoriesSubcategories,
    name: completeProduct?.name,
    image: null,
    recipe: completeProduct?.recipe,
    valability: completeProduct?.valability,
    unitOfMeasure: completeProduct?.unitOfMeasure,
    useInstructions: useInstructions,
    specifications: specifications,
    warnings: warnings,
    descriptions: descriptions,
    pricesByQnt: pricesByQnt,
    selectedIngredientsOptions: ingredients,
    selectedIngredientsIds: [],
  };

  return initialValues;
};
