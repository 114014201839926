import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import useGetProductsWithNameImage from "../../../myHooks/product/useGetProductsWithNameImage";
import SearchBar from "../../Components/SearchBar";
import { useDeleteProduct } from "../../../myHooks/myMutations/deleteMutations/deleteProductMutation";

const ProductsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;
const SingleProduct = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Name = styled.div``;
const Btn = styled.button``;
const BtnsDiv = styled.div``;

const ListOfProducts = () => {
  const { data: productsWithNameImage } = useGetProductsWithNameImage();
  //console.log("productsWithNameImage:", productsWithNameImage);
  const [filteredProducts, setFilteredProducts] = useState([]);
  useEffect(() => {
    if (productsWithNameImage) {
      setFilteredProducts(productsWithNameImage);
    }
  }, [productsWithNameImage]);

  const deleteProduct = useDeleteProduct();
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Lista produse</h5>
      <Link className="mb-4" to="/admin/adauga-produs">
        Adaugă produs
      </Link>
      <SearchBar
        list={productsWithNameImage}
        onResults={setFilteredProducts}
        style={{ width: "80%" }}
      />
      <ProductsContainer>
        {filteredProducts &&
          filteredProducts.map((product) => {
            return (
              <SingleProduct key={product.id}>
                <img
                  src={product.image}
                  alt="product image"
                  style={{ width: "100px" }}
                />
                <Name>{product.name}</Name>
                <BtnsDiv>
                  <Btn type="button" className="btn btn-info me-3">
                    <Link
                      to={`/admin/vezi-produs/${product.id}`}
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Vezi produs
                    </Link>
                  </Btn>
                  <Btn type="button" className="btn btn-warning me-3">
                    <Link
                      to={`/admin/editeaza-produs/${product.id}`}
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Editeaza
                    </Link>
                  </Btn>
                  <Btn
                    type="button"
                    className="btn btn-danger"
                    onClick={() => deleteProduct(product.id)}
                  >
                    Sterge
                  </Btn>
                </BtnsDiv>
              </SingleProduct>
            );
          })}
      </ProductsContainer>
    </div>
  );
};

export default ListOfProducts;
