import React, { useState } from "react";

const SearchBar = ({ onResults, list, style }) => {
  const [query, setQuery] = useState("");

  const handleSearch = (query) => {
    setQuery(query);
    const lowercasedQuery = query.toLowerCase();
    const filtered = list.filter((item) =>
      item.name.toLowerCase().includes(lowercasedQuery)
    );
    onResults(filtered); // Pass the filtered results back to the parent
  };
  return (
    <input
      type="text"
      placeholder="Search..."
      value={query}
      onChange={(e) => handleSearch(e.target.value)}
      className="form-control mb-3"
      style={style}
    />
  );
};

export default SearchBar;
