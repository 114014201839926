import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemesDataProvider } from "./Contexts/ThemesDataContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minute - toate datele vor rămâne proaspete timp de 5 minute
      cacheTime: 10 * 60 * 1000, // 10 minute - datele rămân în cache timp de 10 minute
      refetchOnWindowFocus: false, // Dezactivează refetch-ul automat pe focus
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ThemesDataProvider>
          <App />
        </ThemesDataProvider>

        <ReactQueryDevtools />
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);
