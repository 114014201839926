import React from "react";
import { useParams } from "react-router-dom";
import useGetCompleteProductById from "../../../myHooks/product/useGetCompleteProductById";
import styled from "@emotion/styled";
import ImageAndName from "../../Components/SingleProduct/ImageAndName";
import MenuItemsCategoriesSubcategories from "../../Components/SingleProduct/MenuItemsCategoriesSubcategories";
import Specifications from "../../Components/SingleProduct/Specifications";
import UseInstructions from "../../Components/SingleProduct/UseInstructions";
import Warnings from "../../Components/SingleProduct/Warnings";
import Descriptions from "../../Components/SingleProduct/Descriptions";
import PricesValabilityUM from "../../Components/SingleProduct/PricesValabilityUM";
import Recipe from "../../Components/SingleProduct/Recipe";
import Ingredients from "../../Components/SingleProduct/Ingredients";

const ProductWrap = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const SingleProduct = () => {
  const params = useParams();
  const productId = params.id;
  console.log("productId:", productId);
  const { data: completeProduct } = useGetCompleteProductById(productId);
  console.log("completeProduct", completeProduct);
  return (
    <>
      {completeProduct && (
        <ProductWrap>
          <ImageAndName completeProduct={completeProduct} />
          <MenuItemsCategoriesSubcategories completeProduct={completeProduct} />
          <PricesValabilityUM completeProduct={completeProduct} />
          <Specifications completeProduct={completeProduct} />
          <UseInstructions completeProduct={completeProduct} />
          <Warnings completeProduct={completeProduct} />
          <Descriptions completeProduct={completeProduct} />
          <Recipe completeProduct={completeProduct} />
          <Ingredients completeProduct={completeProduct} />
        </ProductWrap>
      )}
    </>
  );
};

export default SingleProduct;
