import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useCategoriesData } from "../../../Contexts/CategoriesDataContext";
import { useSubcategoriesData } from "../../../Contexts/SubcategoriesDataContext";
import { useMenuItemsData } from "../../../Contexts/MenuItemsDataContext";
import { useDeleteSubcategory } from "../../../myHooks/myMutations/deleteMutations/deleteSubcategoryMutation";

const ContentWrap = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;
const MenuItemWrap = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
`;
const MenuItemName = styled.div`
  font-weight: 600;
  color: red;
`;
const CategoryWrap = styled.div`
  border: 1px dotted grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
const CategoryName = styled.div`
  font-weight: 600;
  color: blue;
`;
const SubcategoriesListWrap = styled.div`
  width: 100%;
`;

const SingleSubcategory = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 3px 30px 3px 30px;
`;
const SubcategoryName = styled.div``;
const SubcategoryPath = styled.div``;
const BtnsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const Btn = styled.button``;

const ListOfSubcategories = () => {
  const { data: categoriesData } = useCategoriesData();
  const { data: subcategoriesData } = useSubcategoriesData();
  const { data: menuItemsData } = useMenuItemsData();

  const deleteSubcategory = useDeleteSubcategory();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
      <h5>Lista categorii</h5>
      <Link className="mb-4" to="/admin/adauga-subcategorie">
        Adaugă subcategorie
      </Link>
      {menuItemsData && categoriesData && subcategoriesData && (
        <ContentWrap>
          {menuItemsData.map((menuItem) => {
            const categoriesForMenuItem = categoriesData.filter(
              (category) => category.menuItemId === menuItem.id
            );
            const existsSubcategoriesForMenuItem = categoriesForMenuItem.some(
              (category) => {
                return subcategoriesData.some(
                  (subcategory) => subcategory.categoryId === category.id
                );
              }
            );
            return (
              <div className="w-100" key={menuItem.id}>
                {existsSubcategoriesForMenuItem &&
                  categoriesForMenuItem &&
                  categoriesForMenuItem.length > 0 && (
                    <MenuItemWrap>
                      <MenuItemName>Menu item : {menuItem.name}</MenuItemName>
                      {categoriesForMenuItem.map((category) => {
                        const subcatgoriesForCategory =
                          subcategoriesData.filter(
                            (subcategory) =>
                              subcategory.categoryId === category.id
                          );

                        return (
                          <div className="w-100" key={category.id}>
                            {subcatgoriesForCategory &&
                              subcatgoriesForCategory.length > 0 && (
                                <CategoryWrap>
                                  <CategoryName>
                                    Categoria : {category.name}
                                  </CategoryName>
                                  <SubcategoriesListWrap>
                                    {subcatgoriesForCategory.map(
                                      (subcategory) => {
                                        return (
                                          <SingleSubcategory
                                            key={subcategory.id}
                                          >
                                            <SubcategoryName>
                                              Nume : {subcategory.name}
                                            </SubcategoryName>
                                            <SubcategoryPath>
                                              Path : {subcategory.path}
                                            </SubcategoryPath>
                                            <BtnsDiv>
                                              <Btn
                                                className="btn "
                                                style={{
                                                  backgroundColor:
                                                    " rgba(255,215,0,1)",
                                                }}
                                              >
                                                <Link
                                                  to={`/admin/editeaza-subcategorie/${subcategory.id}`}
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  Editează
                                                </Link>
                                              </Btn>
                                              <Btn
                                                className="btn "
                                                style={{
                                                  backgroundColor:
                                                    "rgba(184,15,10,1)",
                                                  color: "white",
                                                }}
                                                onClick={() =>
                                                  deleteSubcategory(
                                                    subcategory.id
                                                  )
                                                }
                                              >
                                                Sterge
                                              </Btn>
                                            </BtnsDiv>
                                          </SingleSubcategory>
                                        );
                                      }
                                    )}
                                  </SubcategoriesListWrap>
                                </CategoryWrap>
                              )}
                          </div>
                        );
                      })}
                    </MenuItemWrap>
                  )}
              </div>
            );
          })}
        </ContentWrap>
      )}
    </div>
  );
};

export default ListOfSubcategories;
