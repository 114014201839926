export const addSubcategoryInitialValues = {
  name: "",
  path: "",
  selectedMenuItemId: "",
  selectedMenuItemOption: {
    value: "",
    label: "",
  },
  selectedCategoryId: "",
};
